<template>
    <div class="content-wrapper">
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Chat</h1>
                    </div>
                </div>
            </div>
        </div>
        <section class="content">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Contatos</h3>
                    <div class="card-tools">
                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                            <i class="fas fa-minus"></i>
                        </button>
                        <button type="button" class="btn btn-tool" data-card-widget="remove">
                            <i class="fas fa-times"></i>
                        </button>
                    </div>
                </div>
                <div class="card-body p-0">
                    <ul class="products-list product-list-in-card pl-2 pr-2">
                        <li class="item" v-for="chat in chats" :key="chat.id" @click="show(chat.student_id)">
                            <div class="product-img">
                                <img src="../../assets/avatar.png" alt="Imagem do Aluno" class="img-size-50 img-circle elevation-2">
                            </div>
                            <div class="product-info">
                                <text class="product-title">{{ chat.student.name }}
                                    <text class="float-right">{{ new Date(chat.created_at).toLocaleString() }}</text>
                                </text>
                                <span class="product-description">{{ chat.message }}</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="card-footer">
                    <Pagination @updateData="chats = $event" @isBusy="isBusy = $event" :url="url"/>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Pagination from '../partials/Pagination.vue'
import { CHAT_INDEX } from '../../constants/api'

export default {
    components: { Pagination },
    data() {
        return {
            avatar: '../../assets/avatar.png',
            chats: [],
            url: CHAT_INDEX,
        }
    },
    methods: {
        show(studentId) {
            this.$router.push({ path: `/chat/${studentId}` })
        }
    }
}
</script>

<style scoped>
    .item:hover {
        cursor: pointer;
    }
</style>
